@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  height: 100vh;
  width: 100vw;
  margin: 0;
}

#root {
  height: 100%;
}

@layer components {
  /* mui accordian styles for layer section  */
  .accordianSummary {
    @apply my-0 min-h-max border border-b-2 border-red-700 box-border !important;
  }
  .accordianDetails {
    @apply h-1/2 p-2 !important;
  }
}
