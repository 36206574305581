.box-showl {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.resize-able {
  resize: vertical;
  overflow: auto;
  max-width: 500px;
  min-width: 300px;
}
.apexcharts-svg {
  width: 100% !important;
}
.css-1nuo49t-MuiButtonBase-root-MuiTab-root {
  text-transform: capitalize !important;
  padding: 11px 11px !important;
  font-weight: 500 !important;
}
.css-heg063-MuiTabs-flexContainer {
  justify-content: end !important;
  padding-right: 20px;
}
.MuiTabs-indicator {
  background-color: #4a4844 !important;
}
.css-13xfq8m-MuiTabPanel-root {
  padding: 0 !important;
}

.resizable-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  cursor: grab;
  user-select: none;
}

.sideanimation {
  animation: slide-right 0.7s ease-in-out forwards;
  -webkit-animation: slide-right 0.7s ease-in-out forwards;
}

@keyframes slide-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.calfor-drgg {
  width: calc(100% - 400px);
  margin-left: 400px;
}

.scrollcsss {
  overflow: auto;
}

#cesiumContainer {
  height: 100vh;
  width: 100%;
}
.cesium-widget-credits {
  display: none !important;
}

.cesium-infoBox {
  background-color: rgb(15, 110, 134) !important;
  position: absolute !important;
  top: 2rem;
  left: 40%;
  box-shadow: none;
}

.image-slider button {
  top: 55% !important;
  width: 30px !important;
  left: 0 !important;
}

.image-slider button:last-child {
  left: 90% !important;
}

#slider {
  position: absolute;
  left: 50%;
  top: 0px;
  background-color: #d3d3d3;
  width: 5px;
  height: 100%;
  z-index: 9999;
}

#slider:hover {
  cursor: ew-resize;
}

.cesium-viewer-toolbar {
  display: flex;
  flex-direction: column !important;
  gap: 0.5rem !important;
  align-items: flex-end !important;
}

#tooltip {
  display: none;
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  background-color: black;
  color: white;
  top: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
}
#box:hover #tooltip {
  opacity: 1;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border: 1px solid #f1f1f1;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.cesium-viewer-geocoderContainer > form {
  border-radius: 4px !important;
  border: 1px solid white !important;
}

.cesium-geocoder-searchButton,
.cesium-geocoder-input {
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  -o-border-radius: 4px !important;
}
.cesium-button {
  border-radius: 4px !important;
  border: 1px solid white !important;
}
.cesium-button:hover {
  color: #ffffff00 !important;
  fill: #ffffff00 !important;
  background: rgba(255, 255, 255, 0) !important;
  border-color: rgba(170, 238, 255, 0) !important;
  box-shadow: 0 0 0 rgba(170, 238, 255, 0) !important;
  border: 1px solid white !important;
}

.cesium-viewer-geocoderContainer:hover,
.cesium-geocoder-input {
  border-color: rgba(255, 255, 255, 0) !important;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0) !important;
}
.cesium-geocoder-searchButton {
  height: 31px !important;
}
/* .actionBarOfBottomReportTable .MuiTabs-scroller,
.MuiBox-root {
  height: max-content !important;
  justify-content: center !important;
  align-items: center !important;
} */

.reportTableTabHead {
  font-family: "OpenSans-Regular" !important;
  text-transform: none !important;
  font-weight: 600 !important;
  padding: 0.4rem 0.8rem !important;
  border-radius: 0.5rem !important ;
  -webkit-border-radius: 0.5rem !important ;
  -moz-border-radius: 0.5rem !important ;
  -ms-border-radius: 0.5rem !important ;
  -o-border-radius: 0.5rem !important ;
  /* min-height: max-content !important; */
}
.reportTableTabHead.Mui-selected {
  background: rgba(194, 233, 255, 0.552) !important;
  border-bottom: none !important;
  outline: none !important;
}
.MuiTabs-indicator {
  background-color: transparent !important;
}

.cesium-viewer-geocoderContainer {
  display: none;
}

#BrandingAndStatsBar {
  background-color: rgba(0, 0, 0, 0.81);
}

/* GIS leaflet styles  */
/* sidebar nav item  */
.sidebar-active-nav-item {
  box-shadow: 1px 1px 0px #ffd700;
}

.MuiSlider-thumb {
  height: 12px !important;
  width: 12px !important;
}
.MuiSlider-track {
  height: 6px;
}
