@import url(./fonts.css);
body {
  /* font-family: "OpenSans-Regular"; */
  font-family: "Nunito", sans-serif;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.5s;
}

/* -----------------------------------------------seekbar css start - aman ------------------------------------ */
.video-layer-images {
  position: relative;
  margin-top: 27px;
  margin-bottom: 16px;
  height: 47px;
  border: 3px solid #ffffff;
}
.video-layer-images:hover {
  transition: 0.2s;
  border: 3px solid #ffca00;
  height: 47px;
}
.video-img > .seekimg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.transition-c:hover {
  transition: 0.3s;
}

/* ------------------------------------------seekbar end --------------------------------------*/

/* ---------------------------------------navbar css start--- aman--------------------------- */
.MuiAvatar-root {
  width: 33px !important;
  height: 33px !important;
  font-size: 20px !important;
  background-color: #ffffff !important;
  border: 1px solid #e5e2dc !important;
  border-radius: 25% !important;
}
.MuiSvgIcon-root {
  color: #b5b4b2;
}
.MuiAvatar-root .MuiSvgIcon-root:hover {
  transition: 0.3s;
  color: #3a3a3c;
}

/* ------------------------------navbar css end--- aman -------------------------------*/
.custom-text > .MuiInputBase-root {
  width: 100%;
  height: 40px !important;
}
[type="text"]:focus {
  box-shadow: none !important;
}
.custom-text > .MuiFormLabel-root {
  font-size: 15px !important;
}

.bg-box-model {
  box-shadow:
    rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
}
.bg-box-model-list {
  box-shadow:
    rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 1px;
  border: 1.2px dashed #e8e5dd;
}
.side-btn:hover > .side-btn-icon {
  transition: 0.4s;
  background-color: #a9a7a433;
  width: 42px;
}

.side-btn-icon-onclick {
  background-color: #a9a7a433;
  width: 42px !important;
}

.project-dash {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}
.sidebr-project-dash {
  display: flex;
  flex-direction: column;
  width: 20%;
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
}
.page-name {
  font-weight: 600;
  font-size: 34px;
  line-height: 2rem;
}
.sidebr-project-dashbord {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-left: 4px;
  padding-right: 1px;
  margin-left: 20%;
  background-color: #f4f4f4;
}
.overflowhidden {
  overflow-x: hidden;
}
.navbar-section {
  display: none;
}

.text-nav {
  font-weight: 500;
  color: #b5b4b4;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  font-size: 19px;
}
.parent-text-nav:hover > .text-nav {
  color: #3a3a3c;
  font-weight: 500;
}
.parent-text-nav {
  margin-bottom: 15px;
}
.parent-text-nav:hover {
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 15px;
  border-radius: 10px;
  transition: 0.3s;
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 0px 1px;
  background-color: #efefef;
}
.parent-text-nav-before {
  border-radius: 10px;
  transition: 0.3s;

  box-shadow: rgb(0 0 0 / 5%) 0px 0px 0px 1px;
  background-color: #efefef;
}
.parent-text-nav-before > .text-nav {
  color: #3a3a3c;
}
.font-icon {
  width: 28px;
}
.user-details::after {
  content: "";
  margin-left: 12px;
  margin-right: 12px;
  border-right: 0.4px dashed #bdbdbd;
}
.top-b-nav ::after {
  content: "";
  padding-top: 20px;
  border-top: 1px dashed #b9b8b8;
}
/*--------------------------------- end of nav css : aman ---------------------------*/
.nav-top {
  z-index: 2;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: sticky;
  border-bottom: 1px solid #4a484424;
  justify-content: space-between;
  top: 0;
}

.video-border {
  border: 2px solid #ffca00;
}
.leaflet-control-attribution {
  display: none;
}
.rotated {
  transform: rotate(180deg);
}
/* .reportDataTableHead div {
  border-right: 1px #9ca3afa8 solid;
} */
.reportDataTableRow {
  border: 1px #9ca3afa8 solid;
}
.reportDataTableRow div {
  border-right: 1px #9ca3afa8 solid;
}
.reportDataTableRow:nth-child(even) {
  background-color: #e5e2dc3d;
}

.cesium-baseLayerPicker-dropDown {
  z-index: 11;
}

.measure-label-box {
  width: max-content;
  font-size: 13px;
  font-weight: 700;
  height: 27px;
  line-height: 28px;
  background-color: #202730;
  border-radius: 6px;
  box-shadow: 0 0 1.5px rgba(255, 255, 255, 0.55);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.measure-label {
  /* position: absolute; */
  /* left: 0; */
  /* top: 0; */
  width: max-content;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transform: translate3d(0, 0, 0);
}

.measure-label-text {
  /* display: inline-block; */
  padding: 0 8px;
  height: 27px;
  /* transition: color 0.2s; */
  width: max-content;
  color: white;
}

.measure-label button.remove {
  /* right: -27px; */
  width: 27px;
  height: 27px;
  border-radius: 0 6px 6px 0;
  pointer-events: auto;
  background-color: #202730;
  overflow: visible;
}
.measure-label > button.remove span {
  width: 27px;
  height: 27px;
  /* background-image: url(/assets/images/icons/close-small.1.svg); */
  /* background-size: 11px 11px; */
  background-position: 8px 8px;
  opacity: 0.6;
}

.tooltipOfMeasure {
  position: fixed;
  width: max-content;
  font-size: 13px;
  font-weight: 700;
  height: 27px;
  line-height: 28px;
  background-color: #202730;
  border-radius: 6px;
  box-shadow: 0 0 1.5px rgba(255, 255, 255, 0.55);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  z-index: 9999;
  padding: 0 8px;
  height: 27px;
  color: white;
}

.MuiCircularProgress-colorPrimary {
  color: white !important;
}

.MuiTooltip-tooltip,
.MuiTooltip-tooltipArrow {
  display: flex;
  justify-items: center;
  align-items: center;
  width: max-content;
  font-size: 10px !important;
  letter-spacing: 0.5px;
  word-spacing: 1px;
  font-weight: 500;
  /*height: 27px;
  */ /*line-height: 28px;
  */
  background-color: #202730 !important;
  border-radius: 6px !important;
  box-shadow: 0 0 1.5px rgba(255, 255, 255, 0.55) !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  -o-border-radius: 6px !important;
  padding: auto 8px !important;
  height: 24px !important;
  text-align: center;
  color: white;
}

.MuiTooltip-arrow {
  color: #202730d8 !important;
}

.leaflet-popup-content-wrapper {
  background-color: #202730 !important;
  box-shadow: 0 0 1.5px rgba(255, 255, 255, 0.55) !important;
  color: white;
  border-radius: 6px !important;
}

.leaflet-popup-content {
  margin: 12px 24px !important;
  color: #fff;
  font-family: "Nunito", sans-serif !important;
  font-size: 12px !important;
  word-spacing: 0.05px;
  letter-spacing: 0.5px;
  width: max-content !important;
}

.leaflet-popup-close-button {
  color: white !important;
}
.leaflet-popup-tip {
  background-color: #202730 !important;
}

.MuiAccordionDetails-root {
  background-color: #b0cdf125;
}
.speed-dial-options {
  display: flex;
  gap: 8px;
  align-items: center;
  position: absolute;
  right: 50px;
  bottom: -10px;
}
.speed-dial-options_2 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
  position: absolute;
  bottom: 50px;
  right: 4px;
}

.speed-dial-option {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 6px 10px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  padding: 0px;
  min-width: 0px;
  width: 30px;
  height: 30px;
  z-index: 1050;
  color: rgb(0, 0, 0) !important;
  background-color: rgb(255, 255, 255);
  pointer-events: auto;
}

.speed-dial-option:hover {
  background-color: #f0f0f0;
}
